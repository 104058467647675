<template>
  <div class="companies-button-menu">
    <Button
      icon="pi pi-user"
      class="p-button-rounded button"
      @click="toggle"
      aria-haspopup="true"
      aria-controls="overlay_menu"
    />
    <TieredMenu id="overlay_menu" ref="menu" :model="items" :popup="true" />
  </div>
</template>

<script>
export default {
  name: 'CompaniesButtonMenu',
  data() {
    return {
      companiesOptions: null,
      departmentsOptions: null,
      items: [
        {
          label: 'Company',
          icon: 'pi pi-arrow-circle-right',
        },
        {
          separator: true,
        },
        {
          label: 'Logout',
          icon: 'pi pi-sign-out',
          command: () => this.logout(),
        },
      ],
    };
  },
  methods: {
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    logout() {
      localStorage.clear();
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  @include user-button;
}
</style>
