<template>
  <div class="history">
    <div class="history__sidebar">
      <Sidebar />
    </div>
    <div class="history__content">
      <h1 class="history__content__tittle">History</h1>
      <hr class="history__content__separator" />
      <div class="history__content__container">
        <Table />
      </div>
    </div>
  </div>
</template>

<script>
import Table from '../../components/Table.vue';
import Sidebar from '../../components/Sidebar.vue';

export default {
  components: {
    Table,
    Sidebar,
  },
  name: 'History',
  data() {
    return {
      selectedOptions: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.history {
  display: flex;
  &__sidebar {
    width: 9%;
  }
  &__content {
    width: 91%;
    margin-top: 2rem;
    &__tittle {
      font-weight: bold;
    }
    &__separator {
      width: 98%;
      margin: 2rem auto;
    }
    &__container {
      width: 98%;
      margin: 2rem auto;
    }
  }
}
@media (max-width: 900px) {
  .scales {
    &__sidebar {
      width: 0%;
    }
    &__content {
      width: 98%;
      margin-top: 7rem;
    }
  }
}
</style>
