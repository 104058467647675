<template>
  <div>
    <div class="sidebar">
      <!-- SIDEBAR HEADER -->
      <div class="sidebar__header">
        <div class="sidebar__header__img">
          <img class="img-fluid" src="@/assets/images/glt-logo.png" />
        </div>
      </div>
      <!-- SIDEBAR BODY -->
      <div class="sidebar__body mt-2">
        <div>
            <ul class="list-group list-group-flush">
            <li
                class="list-group-item list-group-item-action"
                id="Scales"
            >
                <a class="the-sidebar-link" @click="changeRoute('/scales')">
                <span class="the-sidebar-link-icon"><i class="pi pi-list"></i></span>
                <span class="the-sidebar-link-title">Scales</span>
                </a>
            </li>
            <li
                class="list-group-item list-group-item-action"
                id="History"
            >
                <a class="the-sidebar-link" @click="changeRoute('/history')">
                <span class="the-sidebar-link-icon"><i class="pi pi-history"></i></span>
                <span class="the-sidebar-link-title">History</span>
                </a>
            </li>
            </ul>
        </div>
        <CompaniesMenu />
      </div>
    </div>
  </div>
</template>

<script>
import CompaniesMenu from './CompaniesButtonMenu.vue';
// import HeaderMobile from './HeaderMobile.vue';

export default {
  name: 'Sidebar',
  components: {
    CompaniesMenu,
    // HeaderMobile,
  },
  data() {
    return {
      departments: [],
    };
  },
  mounted() {
    let path;
    if (this.$route.name === 'EditScale') {
      path = 'Scales';
    } else {
      path = this.$route.name;
    }
    document.getElementById(path).style.background = 'rgba(171, 178, 185, 0.3)';
  },
  methods: {
    changeRoute(route) {
      this.$router.push(route).catch((err) => {
        if (
          err.name !== 'NavigationDuplicated'
          && !err.message.includes('Avoided redundant navigation to current location')
        ) {
          // logError(err);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  background-color: $color-background-dark;
  color: #fff;
  width: 95px;
  height: 100vh;
  z-index: 1000;
  transition: all 0.3s;
  position: fixed;
  &__header {
    background-color: $color-background-darker;
    height: 15vh;
    &__img {
      display: inline-block;
      margin: 0 auto;
      width: 90%;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
  }
  .the-sidebar-link {
    color: #eceff1;
    cursor: pointer;
    width: 100%;
    display: inline-block;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    text-decoration: none;

    .the-sidebar-link-icon {
      margin-bottom: 4px;
    }

    .the-sidebar-link-title {
      font-size: 0.8em;
    }

    .link-icon {
      width: 1.2rem;
    }

    &:hover {
      background-color: #515d6a;
    }
  }

  .the-sidebar-dropdown-link {
    color: #eceff1;
    cursor: pointer;
    width: 100%;
    display: inline-block;
    padding: 0.3rem;

    &:hover {
      background-color: #515d6a;
    }
  }
}
.list-group-item,
.list-group-item:hover {
  background-color: $color-background-dark;
  padding: 0;
}
@media (max-width: 900px) {
  .sidebar {
    background: none;
    &__header {
      display: none;
    }
    &__body {
      display: none;
    }
  }
  .list-group-item,
  .list-group-item:hover {
    background-color: $color-background-darker;
    padding: 0;
  }
}
</style>
